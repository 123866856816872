import React from "react";
import PropTypes from "prop-types";
import * as PrimitiveProgress from "@radix-ui/react-progress";

export default function CircularProgress({
  value = 0,
  minValue = 0,
  maxValue = 100,
  size,
  thickness = 6,
  children,
}) {
  const containerRef = React.useRef(null);
  const [calculatedSize, setCalculatedSize] = React.useState(size || 0);
  const gradientId = `gradient-stroke-${React.useId()}`;

  // Ensure all values are non-negative
  const nonNegativeMinValue = Math.max(0, minValue);
  const nonNegativeMaxValue = Math.max(0, maxValue);
  const nonNegativeValue = Math.max(0, value);

  const validMinValue =
    nonNegativeMinValue >= nonNegativeMaxValue ? 0 : nonNegativeMinValue; // fallback to default minValue
  const validMaxValue =
    nonNegativeMaxValue <= nonNegativeMinValue ? 100 : nonNegativeMaxValue; // fallback to default maxValue

  // Clamp value between validMinValue and validMaxValue
  const clampedValue = Math.min(
    Math.max(nonNegativeValue, validMinValue),
    validMaxValue,
  );

  // Calculate progress within a valid range
  const percentage =
    ((clampedValue - validMinValue) / (validMaxValue - validMinValue)) * 100;
  const radius = (calculatedSize - thickness) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  React.useEffect(() => {
    if (size) {
      setCalculatedSize(size); // Use provided size if specified
    } else if (containerRef.current && children) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setCalculatedSize(Math.max(width, height) + thickness * 2); // Calculate size based on children if size is not provided
    }
  }, [children, thickness, size]);

  return (
    <PrimitiveProgress.Root
      value={clampedValue}
      max={validMaxValue}
      className="relative inline-flex items-center justify-center"
    >
      {calculatedSize > 0 && (
        <PrimitiveProgress.Indicator asChild={true}>
          <svg width={calculatedSize} height={calculatedSize}>
            <defs>
              <radialGradient
                id={gradientId}
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform={`translate(${calculatedSize / 2} ${
                  calculatedSize / 2
                }) rotate(-90) scale(${radius * 1.5} ${radius / 2.5})`}
              >
                <stop offset="0.105" stopColor="#9258FF" />
                <stop offset="1" stopColor="#5D15E3" />
              </radialGradient>
            </defs>

            <circle
              cx={calculatedSize / 2}
              cy={calculatedSize / 2}
              r={radius}
              stroke={`url(#${gradientId})`}
              strokeWidth={thickness}
              fill="none"
              strokeDasharray={circumference}
              strokeDashoffset={offset}
              strokeLinecap="round"
              transform={`rotate(-90 ${calculatedSize / 2} ${
                calculatedSize / 2
              })`}
            />
          </svg>
        </PrimitiveProgress.Indicator>
      )}

      {children && (
        <div
          ref={containerRef}
          className="absolute flex items-center justify-center p-[2px]"
        >
          {children}
        </div>
      )}
    </PrimitiveProgress.Root>
  );
}

CircularProgress.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  thickness: PropTypes.number,
  size: PropTypes.number,
};
