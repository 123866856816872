import React from "react";

import useProfile from "../useProfile";
import useClaimedBadges from "../useClaimedBadges";
import useEarnedBadges from "../useEarnedBadges";

export default function useBadgeRulesProgress({ id }) {
  const { profile } = useProfile();
  const { claimedBadges } = useClaimedBadges();
  const { earnedBadges } = useEarnedBadges();

  const badgeRulesProgress = React.useMemo(() => {
    if (!id || !profile) return [];

    const badge = profile.badgeProgress?.find(({ badgeId }) => badgeId === id);

    if (!badge?.badgeRules) {
      const claimedBadge = claimedBadges.find(
        ({ id: badgeId }) => badgeId === id,
      );
      const earnedBadge = earnedBadges.find(
        ({ id: badgeId }) => badgeId === id,
      );
      const badgeRules = claimedBadge?.rules || earnedBadge?.rules;

      if (!badgeRules) return [];

      return badgeRules.map((rule) => ({
        ruleId: rule.id,
        currentScore: rule.score,
        scoreNeeded: rule.score,
        completionPercentage: 100,
        isComplete: true,
      }));
    }

    return badge.badgeRules.map((rule) => ({
      ...rule,
      completionPercentage: Math.min(
        (rule.currentScore / rule.scoreNeeded) * 100,
        100,
      ),
      isComplete: rule.currentScore >= rule.scoreNeeded,
    }));
  }, [claimedBadges, earnedBadges, id, profile]);

  return badgeRulesProgress;
}
