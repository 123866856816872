import React from "react";

import useBadgeRulesProgress from "../useBadgeRulesProgress";

export default function useBadgeRulesCombinedProgress({ id }) {
  const badgeRulesProgress = useBadgeRulesProgress({ id });

  const badgeRulesCombinedProgress = React.useMemo(() => {
    if (!badgeRulesProgress || badgeRulesProgress.length === 0) {
      return {
        combinedScore: 0,
        combinedScoreNeeded: 0,
        combinedCompletionPercentage: 0,
        isCombinedComplete: false,
      };
    }

    const combinedScore = badgeRulesProgress.reduce(
      (acc, { currentScore }) => acc + currentScore,
      0,
    );

    const combinedScoreNeeded = badgeRulesProgress.reduce(
      (acc, { scoreNeeded }) => acc + scoreNeeded,
      0,
    );

    return {
      combinedScore,
      combinedScoreNeeded,
      combinedCompletionPercentage: Math.min(
        (combinedScore / combinedScoreNeeded) * 100,
        100,
      ),
      isComplete: combinedScore >= combinedScoreNeeded,
    };
  }, [badgeRulesProgress]);

  return badgeRulesCombinedProgress;
}
