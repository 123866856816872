import React from "react";

export default function useListPagination({ list = [], listSize = 10 } = {}) {
  const [currentListIndex, setCurrentListIndex] = React.useState(1);

  const maxIndex = Math.ceil(list.length / listSize);
  const isFirstList = list.length === 0 || currentListIndex === 1;
  const isLastList = list.length === 0 || currentListIndex === maxIndex;

  const paginatedList = React.useMemo(() => {
    if (!list.length) return list;
    const offset = (currentListIndex - 1) * listSize;

    return list.slice(offset, offset + listSize);
  }, [list, currentListIndex, listSize]);

  const nextList = React.useCallback(() => {
    const nextListIndex = currentListIndex + 1;

    if (nextListIndex > maxIndex) return;

    setCurrentListIndex(nextListIndex);
  }, [currentListIndex, maxIndex]);

  const prevList = React.useCallback(() => {
    const prevListIndex = currentListIndex - 1;
    if (prevListIndex < 1 || list.length === 0) return;

    setCurrentListIndex(prevListIndex);
  }, [currentListIndex, list.length]);

  const gotToList = React.useCallback(
    (index) => {
      if (index < 1 || index > maxIndex) return;

      setCurrentListIndex(index);
    },
    [maxIndex],
  );

  return {
    paginatedList,
    currentListIndex,
    isFirstList,
    isLastList,
    nextList,
    prevList,
    gotToList,
  };
}
