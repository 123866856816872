import React from "react";
import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../constants";
import api from "../../api";
import useClaimedBadges from "../useClaimedBadges";
import useEarnedBadges from "../useEarnedBadges";

const client = api();

export default function useBadgesQuery(options = {}) {
  const oneHour = 1000 * 60 * 60;
  const {
    data: publishedBadges = [],
    isLoading: isBadgesLoading,
    isError: badgesError,
  } = useQuery([QUERY_KEYS.BADGES], client.getBadges, {
    staleTime: oneHour * 4,
    ...options,
  });
  const { earnedBadges } = useEarnedBadges();
  const { claimedBadges } = useClaimedBadges();

  const badges = React.useMemo(() => {
    const badgeMap = new Map();

    publishedBadges.forEach((badge) => {
      badgeMap.set(badge.id, { ...badge, isEarned: false, isClaimed: false });
    });

    earnedBadges.forEach((badge) => {
      if (badgeMap.has(badge.id)) {
        const existingBadge = badgeMap.get(badge.id);
        badgeMap.set(badge.id, { ...existingBadge, isEarned: true });
      } else {
        badgeMap.set(badge.id, { ...badge, isEarned: true, isClaimed: false });
      }
    });

    claimedBadges.forEach((badge) => {
      if (badgeMap.has(badge.id)) {
        const existingBadge = badgeMap.get(badge.id);
        badgeMap.set(badge.id, { ...existingBadge, isClaimed: true });
      } else {
        badgeMap.set(badge.id, { ...badge, isEarned: false, isClaimed: true });
      }
    });

    const badgesArray = Array.from(badgeMap.values()).sort(
      (a, b) => new Date(b.publishStartsAt) - new Date(a.publishStartsAt),
    );

    return badgesArray;
  }, [publishedBadges, earnedBadges, claimedBadges]);

  return { badges, isBadgesLoading, badgesError };
}
