import React from "react";
import PropTypes from "prop-types";

const VideoHeader = React.forwardRef(({ videoDetails }, ref) => (
  <div className="flex flex-row w-full justify-between items-center bg-white py-sm px-sm rounded-tr rounded-tl min-h-video-title border-b ">
    <div className="flex flex-col md:flex-row w-4/6 gap-xs lg:gap-sm mr-sm items-start md:items-center">
      {videoDetails.icon ? (
        <img
          className="w-xl h-auto hidden md:block"
          src={videoDetails.icon}
          alt={videoDetails.description}
        />
      ) : null}

      <div className="flex flex-col justify-center">
        <h3 className="gn-video-title font-bold text-black">
          {videoDetails.title}
        </h3>
      </div>
    </div>

    {videoDetails.sponsorIcon || videoDetails.sponsorGptAdId ? (
      <div ref={ref} className="w-2/6 lg:w-screen-xs px-xs">
        <p className="flex flex-col lg:flex-row justify-end items-center gap-xs gn-text-xs font-bold text-black uppercase">
          {videoDetails.sponsorGptAdId ? (
            <>
              {videoDetails.sponsorGptAdRendered &&
                videoDetails.sponsorLanguage}
              <span
                id={videoDetails.sponsorGptAdId}
                className="w-[120px] h-[60px]"
              />
            </>
          ) : (
            <>
              {videoDetails.sponsorLanguage}
              <a
                href={videoDetails.partnerUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-2xl px-sm"
                  src={videoDetails.sponsorIcon}
                  alt={videoDetails.partnerName}
                />
              </a>
            </>
          )}
        </p>
      </div>
    ) : null}
  </div>
));

VideoHeader.propTypes = {
  videoDetails: PropTypes.shape({
    contentTags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    icon: PropTypes.string,
    sponsorGptAdId: PropTypes.string,
    sponsorGptAdRendered: PropTypes.bool,
    sponsorIcon: PropTypes.string,
    sponsorLanguage: PropTypes.string,
    partnerUrl: PropTypes.string,
    partnerName: PropTypes.string,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    printable: PropTypes.object,
  }).isRequired,
};

export default VideoHeader;
