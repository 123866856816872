import React from "react";
import PropTypes from "prop-types";
import * as PrimitiveProgress from "@radix-ui/react-progress";
import { twMerge } from "tailwind-merge";

export const VARIANTS = {
  VIVID: "vivid",
  ORANGE: "orange",
};

export default function Progress({
  label,
  valueLabel,
  value = 0,
  minValue = 0,
  maxValue = 100,
  variant = VARIANTS.VIVID,
  className,
}) {
  // Ensure all values are non-negative
  const nonNegativeMinValue = Math.max(0, minValue);
  const nonNegativeMaxValue = Math.max(0, maxValue);
  const nonNegativeValue = Math.max(0, value);

  const validMinValue =
    nonNegativeMinValue >= nonNegativeMaxValue ? 0 : nonNegativeMinValue; // fallback to default minValue
  const validMaxValue =
    nonNegativeMaxValue <= nonNegativeMinValue ? 100 : nonNegativeMaxValue; // fallback to default maxValue

  // Clamp value between validMinValue and validMaxValue
  const clampedValue = Math.min(
    Math.max(nonNegativeValue, validMinValue),
    validMaxValue,
  );

  // Calculate progress within a valid range
  const progress =
    ((clampedValue - validMinValue) / (validMaxValue - validMinValue)) * 100;

  return (
    <div
      className={twMerge("flex flex-col space-y-2 text-gray-900", className)}
    >
      <div className="flex flex-row justify-between">
        {label && <span className="text-sm font-extrabold">{label}</span>}

        {valueLabel && (
          <span className="text-sm font-extrabold ml-auto">{valueLabel}</span>
        )}
      </div>

      <PrimitiveProgress.Root
        className={twMerge(
          "overflow-hidden bg-purple-300 rounded-full w-full h-4",
          variant === VARIANTS.VIVID && "bg-purple-300",
          variant === VARIANTS.ORANGE && "bg-off-white",
        )}
        style={{
          // Fix overflow clipping in Safari
          // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
          transform: "translateZ(0)",
        }}
        value={progress}
        asChild={false}
      >
        <PrimitiveProgress.Indicator
          className={twMerge(
            "bg-gradient-to-br w-full h-full",
            variant === VARIANTS.VIVID && "from-purple-300 to-purple-700",
            variant === VARIANTS.ORANGE && "from-orange-950 to-orange-700",
          )}
          style={{ transform: `translateX(-${100 - progress}%)` }}
        />
      </PrimitiveProgress.Root>
    </div>
  );
}

Progress.propTypes = {
  label: PropTypes.string,
  valueLabel: PropTypes.string,
  value: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  variant: PropTypes.oneOf([VARIANTS.ORANGE, VARIANTS.VIVID]),
  className: PropTypes.string,
};
