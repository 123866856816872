import React from "react";

import useClaimedBadges from "../useClaimedBadges";

export default function useBadgeClaimCount({ id } = {}) {
  const { claimedBadges = [] } = useClaimedBadges();

  const badgeClaimCount = React.useMemo(() => {
    if (!id || claimedBadges.length === 0) return 0;

    return claimedBadges.filter((badge) => badge.id === id).length;
  }, [id, claimedBadges]);

  return {
    badgeClaimCount,
  };
}
