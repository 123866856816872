export default ({ get, post, checkStatus, toJSON }) => ({
  getBadges: () =>
    get(`universe/badges`)
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
  claimBadge: (badgeId) =>
    post(`universe/badges/${badgeId}/claim`)
      .then((response) => checkStatus({ response, method: "POST" }))
      .then(toJSON),
});
